.app {
    width: 100%;
    min-height: 100vh;
    height: auto !important;
    /* background: linear-gradient(to right, #62ac13, #dcce50, #bfd888); */
    /* background: linear-gradient(to right, #3fa194, #59c578, #c0fa9a); */
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* border: 1px solid #c32d2d; */
  }
  
  .header {
    width: 100%;
    height: 1.6rem;
    padding-top: .2rem;
    text-align: center;
  }
  
  .logo {
    width: 100%;
    height: 1.36rem;
  }


  :global(.app_bg) {
    background: linear-gradient(to right, #62ac13, #dcce50, #bfd888);
  }