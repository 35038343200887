* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 37.5px;
}

body {
  /* font-size: 24px; */
  font-family: 'Times New Roman', Times, serif;
  color: #474747;
}

a {
  text-decoration: none;
}

a:link,
a:visited {
  color: inherit;
}

.pos_rela {
  position: relative;
}

.blue {
  background: linear-gradient(to top, rgba(52, 114, 255, 1), rgba(52, 114, 255, 0)) !important;
}

.yellow {
  background: linear-gradient(to top, rgba(255, 213, 52, 1), rgba(255, 213, 52, 0)) !important;
}

.green {
  background: linear-gradient(to top, rgba(108, 191, 42, 1), rgba(108, 191, 42, 0)) !important;
}

.pink {
  background: linear-gradient(to top, rgba(236, 39, 124, 1), rgba(236, 39, 124, 0)) !important;
}

.purple {
  background: linear-gradient(to top, rgba(152, 19, 160, 1), rgba(152, 19, 160, 0)) !important;
}