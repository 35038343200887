.install {
    color: #ffffff;
    /* background-color: #3fa194; */
    background: linear-gradient(to top, #3fa194, #59c578, #c0fa9a);
    width: 70%;
    height: 41px;
    font-size: 20px;
    margin: 8px;
    border-radius: 20px;
    border: 0px;
}

.game_title {
    color: #FFFFFF;
    font-size: .76rem;
    position: absolute;
    top: 5%;
    z-index: 10;
    left: 7%;
    font-weight: bold;
}

.game_detail {
    width: 100%;
    /* padding: .4rem 0; */
    display: flex;
    justify-content: center;
}

.toolbar {
    width: 90%;
    position: absolute;
    bottom: 0;
    height: 2.8rem;
    /* // background-color: #FFFFFF; */
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.like_item {
    width: 1.2rem;
    height: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: .36rem;
    color: #FFFFFF;
}

.like_item_image {
    width: .8rem;
    height: .8rem;
}

.progress-container {
    width: 100%;
    background-color: #f0f0f0;
    position: relative;
    border-radius: .4rem;
    font-size: .48rem;
}

.progress-bar {
    height: 100%;
    background-color: #5bda7b;
    transition: width 0.3s;
    border-radius: .4rem;
    /* 使用transition实现动画效果 */
}

.play_btn {
    position: absolute;
    top: 35%;
    left: 38%;
    /* // transform: translate(-50%, -50%); */
    animation: jello 1.5s 1s infinite both;
}

.play_btn_image {
    width: 3.3rem;
    height: 3.3rem;
}

.loading {
    position: absolute;
    top: 45%;
    left: 34%;
    /* // transform: translate(-50%, -50%); */
    width: 4.4rem;
    height: .8rem;
    text-align: center;
    line-height: .8rem;
    color: #FFFFFF;
    font-weight: bold;
}

@keyframes jello {
    0% {
        transform: scaleX(1);
    }

    30% {
        transform: scale3d(1.25, .75, 1)
    }

    40% {
        transform: scale3d(.75, 1.25, 1)
    }

    50% {
        transform: scale3d(1.15, .85, 1)
    }

    65% {
        transform: scale3d(.95, 1.05, 1)
    }

    75% {
        transform: scale3d(1.05, .95, 1)
    }

    to {
        transform: scaleX(1)
    }
}

.more_games {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.play_game {
    width: 100%;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    background-color: #cccccc;
    z-index: 999;
}

.left_back {
    width: 2.2rem;
    height: 1.4rem;
    background-color: rgba(255, 255, 255, 0.3);
    position: fixed;
    border-radius: 0 40% 40% 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    z-index: 9999;
}

.left_back_image {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: -10%;
    border-radius: 50%;
}

.overflow {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
}


.game_more_btn {
    margin-bottom: .6rem;
    width: 100%;
    height: .9rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    font-weight: bold;
  }
  
  
  .more_btn {
    width: .36rem;
    height: .35rem;
    margin-left: .6rem;
  }