.app {
  width: 100%;
  min-height: 100vh;
  height: auto !important;
  /* background: linear-gradient(to right, #3fa194, #59c578, #c0fa9a); */
  color: #ffffff;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* border: 1px solid #c32d2d; */
}
.hot_games {
  width: 100%;
  /* margin: .6rem 0; */
  /* height: 5.3rem; */
}

.hot_title {
  width: 100%;
  height: 0.84rem;
  font-size: 0.56rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  background: linear-gradient(
    to right,
    rgba(255, 100, 52, 1),
    rgba(255, 100, 52, 0)
  );
}

.hot_icon {
  width: 0.4rem;
  margin-left: 0.76rem;
  height: 0.5rem;
}

.hot_game_list {
  margin: 0 1.28rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.hot_game_item {
  height: 3.6rem;
  width: 3.6rem;
  padding-top: 0.4rem;
}

.hot_img {
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 0.4rem;
}

.more_games {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.more_game_title {
  width: 100%;
  height: 0.84rem;
  font-size: 0.56rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  background: linear-gradient(
    to right,
    rgba(52, 114, 255, 1),
    rgba(52, 114, 255, 0)
  );
}

.game_icon {
  width: 0.6rem;
  margin-left: 0.76rem;
  height: 0.4rem;
}

.cate_title {
  width: 100%;
  height: 0.84rem;
  font-size: 0.56rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  background: linear-gradient(
    to right,
    rgba(255, 213, 52, 1),
    rgba(255, 213, 52, 0)
  );
}

.cate_icon {
  width: 0.4rem;
  margin-left: 0.76rem;
  height: 0.4rem;
}
