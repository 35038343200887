.game_cate {
    padding: .6rem 0;
    min-height: 7rem;
    width: 14rem;
}

.cate_text {
    font-size: .4rem;

}

.cate_head {
    display: flex;
    height: 6.2rem;
    flex-flow: row;
    justify-content: space-between;
}

.cate_left {
    width: 6.52rem;
    height: 6.25rem;
    position: relative;

}

.cate_left_title {
    width: 100%;
    height: 100%;
    border-radius: 0 0 .8rem .8rem;
    position: absolute;
    bottom: 0%;
    background: linear-gradient(to top, rgba(52, 114, 255, 1), rgba(52, 114, 255, 0));
    font-size: .68rem;
    display: flex;
    justify-content: center;
    align-items: end;
    line-height: 1.2rem;
    color: #FFFFFF;
    font-weight: bold;
}

.right_top_title {
    width: 100%;
    height: 100%;
    border-radius: .4rem;
    position: absolute;
    bottom: 4%;
    font-size: .4rem;
    display: flex;
    justify-content: center;
    align-items: end;
    line-height: .8rem;
    color: #FFFFFF;
    font-weight: bold;
}

.cate_more_title {
    width: 100%;
    height: 2rem;
    border-radius: 0 0 .4rem .4rem;
    position: absolute;
    bottom: 11%;
    background: linear-gradient(to top, rgba(152, 19, 160, 1), rgba(152, 19, 160, 0));
    font-size: .36rem;
    display: flex;
    justify-content: center;
    align-items: end;
    line-height: .8rem;
    white-space: nowrap;
    overflow: hidden;
    color: #FFFFFF;
    font-weight: bold;
}



.cate_right {
    width: 6.8rem;
    border-radius: 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border: 1px solid red; */
}

.right_item_top {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.right_item_bottom {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.cate_right_img {
    width: 2.94rem;
    height: 2.94rem;
    border-radius: .4rem;
}

.cate_right_img_small {
    width: 2.26rem;
    height: 2.26rem;
    border-radius: .4rem;
}

.cate_more {
    margin-top: .4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cate_more_btn {
    width: 4.24rem;
    height: 1.26rem;
    /* background-image: url('../../static/img/cate_more_btn.png'); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    line-height: 1.1rem;
}

.cate_more_list {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.cate_more_item {
    /* width: 20%; */
    height: 3.4rem;
}

.cate_more_img {
    width: 3rem;
    height: 3rem;
    /* padding-right: 20rpx; */
    border-radius: .4rem;
}
