.fanzhuan {
    flex-direction: row-reverse;
    /* 左右颠倒 */
}

.more_game_list {
    padding-top: .6rem;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.game_item {
    width: 100%;
    min-height: 12rem;
    height: auto;
    margin-bottom: .2rem;
    display: flex;
    flex-direction: column;

}

.game_item_top {
    width: 100%;
    display: flex;
    flex-wrap: row wrap;
    justify-content: space-between;
    height: 7.6rem;
}

.game_item_top_left {
    width: 64%;
    height: 7.8rem;
}

.game_item_bottom {
    display: flex;
    width: 100%;
    flex-direction: row;
    height: 4rem;
    margin-top: .2rem;
    justify-content: space-between;
}

.big_img {
    width: 100%;
    height: 7.6rem;
    border-radius: 10%;
}

.small_img {
    width: 3.66rem;
    height: 3.66rem;
    border-radius: 10%;
}

.game_item_top_right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
}

.game_item_top_right_item {
    width: 3.66rem;
    height: 3.66rem;
    display: flex;
}

.game_more_btn {
    margin-bottom: .6rem;
    width: 100%;
    height: .8rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    font-weight: bold;
}

.more_btn {
    width: .36rem;
    height: .4rem;
    margin-left: .6rem;
}