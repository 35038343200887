.footer_main {
    width: 100%;
    background-color: #FFFFFF;
    font-size: .48rem;
  }
  
  .footer_content {
    color: #474747;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .foot_pd {
    padding: .6rem .4rem;
    text-decoration: underline;
  }
  